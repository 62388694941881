<template>
  <div :style="style" class="loader">
    <SvgoIframeLoaderIcon class="loader__logo" />
    <div class="loader__progress">
      <span class="bar"></span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type {
  IBkLoaderProps,
  IBkLoaderEmits,
} from '~/features/bk/components/BKIntegrationLoader/BKIntegrationLoader.types';
import { Colors } from '~/constants/colors.constants';

const ANIMATION_DELAY = 3000;

const emit = defineEmits<IBkLoaderEmits>();
const props = withDefaults(defineProps<Partial<IBkLoaderProps>>(), {
  loaderBarBgColor: 'var(--main-color-300)',
  loaderBarColor: Colors.ADDITIONAL.ORANGE,
  loaderPosition: 'absolute',
  logoHeight: '65px',
  logoWidth: '163px',
});

setTimeout(() => {
  if (!props.isPendingGetCase) emit('update:loader', true);
}, ANIMATION_DELAY);

const style = computed(() => ({
  '--loader-bg': props.loaderBarBgColor,
  '--loader-color': props.loaderBarColor,
  '--loader-position': props.loaderPosition,
  '--logo-height': props.logoHeight,
  '--logo-width': props.logoWidth,
}));
</script>

<style src="./BKIntegrationLoader.scss" scoped lang="scss"></style>
