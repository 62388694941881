import type {
  DemoStore,
  IBkInventoryService,
  InventoryStore,
} from '~/features/bk/services/BkInventoryService/bkInventoryService.types';

import type { IInventoryItem } from '~/repository/modules/bk/bk.types';

export default class BkInventoryService implements IBkInventoryService {
  private readonly demoStore: DemoStore;
  private readonly inventoryStore: InventoryStore;
  private readonly isDemo: () => boolean;

  constructor(demoStore: DemoStore, inventoryStore: InventoryStore) {
    this.isDemo = () => demoStore.isDemo;
    this.demoStore = demoStore;
    this.inventoryStore = inventoryStore;
  }

  async sellWonItem(item: IInventoryItem) {
    if (this.isDemo()) this.demoStore.sellWonItem(item);
    else await this.inventoryStore.sellWonItem(item);
  }

  sellAllItems() {
    if (this.isDemo()) this.demoStore.sellAllItems();
    else this.inventoryStore.sellAllItems();
  }

  newItemsQuantity(): number {
    const { getNewItemsQuantity } = storeToRefs(this.demoStore);
    if (this.isDemo()) return getNewItemsQuantity.value;
    else return 0;
  }

  oldItemsQuantity(): number {
    const { getOldItemsQuantity } = storeToRefs(this.demoStore);
    if (this.isDemo()) return getOldItemsQuantity.value;
    else return 0;
  }

  computeSellAllPrice(): number {
    return 0;
  }
}
